import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";
import { getDetector } from "../../utils/browserDtector.js";

gsap.registerPlugin(ScrollTrigger);

export default class section6 {
	constructor() {
		this.section = document.querySelector(".section6-js");
		this.svgContainer = this.section.querySelector(".section6-svg-js");
		this.svgWrapper = this.svgContainer.querySelector(".slogan-svg-js");
		this.svgitems = this.svgWrapper.querySelectorAll(".item-js");

		this.init();
	}

	init() {

		if (getDetector() != "Safari") {
			this.svgitems.forEach((item) => {
				gsap.fromTo(
					item,
					{
						transformOrigin: item.getAttribute("data-origin"),
						scale: 0,
					},
					{
						scale: 1,
						scrollTrigger: {
							trigger: item,
							//invalidateOnRefresh: true,
							//markers: true,
							toggleActions: "restart none none reverse",
							scrub: true,
							start: "top center",
							end: "+=300",
						},
					}
				);
			});
		}
	}
}
