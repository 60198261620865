import section2 from "./section2.js";
import section3 from "./section3.js";
import section4 from "./section4.js";
import section5 from "./section5.js";
import section6 from "./section6.js";
import section7 from "./section7.js";
import section8 from "./section8.js";
import hero from "./hero-mainpage.js";

export default class mainpage {
    constructor() {

        this.Hero = new hero();
        this.Section2 = new section2();
        this.Section3 = new section3();
        this.Section4 = new section4();
        this.Section5 = new section5();
        this.Section6 = new section6();
        this.Section7 = new section7();
        this.Section7 = new section8();
    }
}
