import BrowserDetector from "browser-dtector";

export function setDetector() {
	const detector = new BrowserDetector(window.navigator.userAgent);

	document.querySelector("html").setAttribute("browser", detector.parseUserAgent().name);
}

export function getDetector() {
    return document.querySelector("html").getAttribute("browser");
}
