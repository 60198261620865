import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

/*-------------------------------------------------------------
Lenis -> smoothscroll
-------------------------------------------------------------*/
export default class SmoothScroll {
	constructor() {
		window.lenis = new Lenis({
			duration: 1.5,
			orientation: "vertical", // vertical, horizontal
			gestureOrientation: "vertical", // vertical, horizontal, both
			smoothWheel: true,
			wheelMultiplier: 1,
			smoothTouch: false,
			touchMultiplier: 2,
			infinite: false,
		});

		this.init();
	}

	init() {
		const raf = (time) => {
			window.lenis.raf(time);
			requestAnimationFrame(raf);
		};

		// for ScrollTrigger
		window.lenis.on("scroll", ScrollTrigger.update);

		gsap.ticker.add((time) => {
			window.lenis.raf(time * 1000);
		});
	}

	scrollTop() {
		window.lenis.scrollTo(0, { immediate: true });
	}

	stop() {
		window.lenis.stop();
	}

	start() {
		window.lenis.start();
	}
}
