import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class nav {
	constructor() {
		//Mobile Menu
		this.isOpenMobileMenu = false;
		this.mobileMenu = null;
		this.burgerMenu = null;
		this.burgerSVG = null;
		this.closeSVG = null;
		this.navMainpage = null;

		//Sticky Nav
		this.stickyNav = null;
		this.stickyAni = null;

		this.createSticky();
		this.catchElements();

		window.addEventListener("resize", (e) => {
			innerWidth > 1199 && this.isOpenMobileMenu ? this.closeMobileMenu() : null;
		});
	}

	catchElements() {
		this.burgerMenu = gsap.utils.toArray(".mobile-menu-js");
		this.burgerSVG = gsap.utils.toArray("#burger-svg");
		this.closeSVG = gsap.utils.toArray("#close-svg");
		this.mobileMenu = document.querySelector(".mobile-menu");
		this.navMainpage = document.querySelector(".nav.mainpage");

		this.burgerMenu.forEach((item) => {
			item.addEventListener("click", (e) => {
				this.isOpenMobileMenu ? this.closeMobileMenu() : this.OpenMobileMenu();
			});
		});
	}

	createSticky() {
		//Sticky Nav Vars
		this.stickyNav = document.querySelector(".nav.sticky");

		gsap.set(this.stickyNav, { y: "-=100%" });

		this.stickyAni = gsap.to(this.stickyNav, {
			y: "+=100%",
			duration: 0.8,
			ease: "power4.inOut",
			paused: true,
			overwrite: true,
		});

		ScrollTrigger.create({
			trigger: document.body,
			start: "100vh top",
			end: 99999,
			//markers: true,
			onUpdate: ({ progress, direction }) => {
				if (direction == 1 || window.scrollY < innerHeight) {
					this.stickyAni.reverse();
				} else if (direction == -1) {
					this.stickyAni.play();
				}
			},
		});
	}

	changeMenuIcon(hide, show) {
		gsap.fromTo(
			hide,
			{
				scale: 1,
			},
			{
				scale: 0,
				ease: "elastic.in(1,0.5)",
				duration: 0.5,
				onComplete: () => {
					hide.forEach((item) => {
						item.classList.add("d-none");
					});

					show.forEach((item) => {
						item.classList.remove("d-none");
					});
				},
			}
		);

		gsap.fromTo(
			show,
			{
				scale: 0,
			},
			{
				scale: 1,
				duration: 1,
				delay: 0.5,
				ease: "elastic.out(1,0.5)",
			}
		);
	}

	OpenMobileMenu() {

		let page = document.querySelector("main").getAttribute("data-barba-namespace");

		if (page == "home") {
			document.querySelector("#white-logo").classList.add("text-color-brand-purple-3");
			this.navMainpage.classList.add("underBorder");
		}

		this.changeMenuIcon(this.burgerSVG, this.closeSVG);

		window.lenis.stop();

		gsap.to(this.mobileMenu, {
			height: "100svh",
			duration: 1,
			ease: "power4",
			overwrite: true,
		});

		this.isOpenMobileMenu = true;
	}

	closeMobileMenu() {
		let page = document.querySelector("main").getAttribute("data-barba-namespace");

		if (page == "home") {
			gsap.delayedCall(.3, () => {
				document.querySelector("#white-logo").classList.remove("text-color-brand-purple-3");
				this.navMainpage.classList.remove("underBorder");
			});
		}

		this.changeMenuIcon(this.closeSVG, this.burgerSVG);

		gsap.to(this.mobileMenu, {
			height: "0",
			duration: 1,
			top: 0,
			ease: "power4",
			overwrite: true,
			onComplete: () => {
				window.lenis.start();
				gsap.utils.toArray(".mobile-menu-trigger").forEach((item) => {
					item.checked = false;
				});
			},
		});

		this.isOpenMobileMenu = false;
	}

	update() {
		gsap.utils.toArray(".mobile-menu-trigger").forEach((item) => {
			item.checked = false;
		});

		this.isOpenMobileMenu ? this.closeMobileMenu() : null;

		const activePage = window.location.pathname;

		document.querySelectorAll("label").forEach((link) => {
			link.classList.remove("menu-active");
		});

		document.querySelectorAll("a").forEach((link) => {
			link.classList.remove("menu-active");
		
		});

		document.querySelectorAll("a").forEach((link) => {
			if (activePage != "/" && link.href.includes(`${activePage}`)) {
				link.classList.add("menu-active");

				if (link.getAttribute("data-submenu")) {
					let linkLabel = link.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector("label");
					linkLabel ? linkLabel.classList.add("menu-active") : null;

					let mainMenu = link.parentNode.parentNode.parentNode.querySelector("a");
					mainMenu.getAttribute("data-mainmenu") ? mainMenu.classList.add("menu-active") : null;
					
				}
			}
		});

		
	}

	navController() {
		let page = document.querySelector("main").getAttribute("data-barba-namespace");

		if (page === "home") {
			document.querySelector(".nav.subpage").classList.add("d-none");
			document.querySelector(".nav.mainpage").classList.remove("d-none");
		} else {
			document.querySelector(".nav.subpage").classList.remove("d-none");
			document.querySelector(".nav.mainpage").classList.add("d-none");
		}
	}
}
