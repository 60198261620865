import { gsap } from "gsap";

export default class gallery {
	constructor() {
		this.lightBox = document.getElementById("lightbox");
		this.wrapper = document.getElementById("main-wrapper");
		this.thumbs = gsap.utils.toArray(".gallery-thumb");
		this.img = document.getElementById("lightbox-img");
		this.isAnimated = false;

		this.currentPic = null;
		this.oldPic = null;
		this.isActive = false;

		this.closeButton = document.querySelector(".lightbox__close");
		this.nextButton = document.querySelector(".lightbox__next");
		this.prevButton = document.querySelector(".lightbox__prev");

		this.currentEl = document.querySelector(".current-num-js");
		this.totalEl = document.querySelector(".total-num-js");

		this.create();
	}

	create() {
		let i = 0;

		this.thumbs.forEach((thumb) => {
			thumb.setAttribute("data-num", i);
			i += 1;

			thumb.addEventListener("click", (e) => {
				this.open(e.target);
			});
		});

		this.closeButton.addEventListener("click", (e) => {
			this.close(e.target);
		});

		this.prevButton.addEventListener("click", (e) => {
			this.prev(e.target);
		});

		this.nextButton.addEventListener("click", (e) => {
			this.next(e.target);
		});

		window.addEventListener(
			"keydown",
			(e) => {
				if (this.isActive) {
					switch (e.code) {
						case "Escape":
							//console.log("escape");
							this.close();
							break;
						case "ArrowLeft":
							//console.log("prev");
							this.prev();
							break;
						case "ArrowRight":
							//console.log("next");
							this.next();
							break;
					}
				}
			},
			true
		);
	}

	open(item) {
		this.isActive = true;
		this.currentPic = item;
		this.img.setAttribute("src", this.currentPic.getAttribute("data-link"));
		window.lenis.stop();
		this.lightBox.classList.remove("hide");

		this.currentEl.innerHTML = Number(item.getAttribute("data-num")) + 1;
		this.totalEl.innerHTML = "/ " + `${this.thumbs.length}`;

		gsap.fromTo(
			this.lightBox,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 1,
			}
		);

		gsap.fromTo(
			this.wrapper,
			{
				scale: 0,
			},
			{
				scale: 1,
				ease: "power4",
				duration: 1,
				delay: 0.2,
			}
		);
	}

	close() {
		this.isActive = false;
		gsap.to(this.lightBox, {
			opacity: 0,
			duration: 1,
			delay: .2,
			ease: "power4",
		});

		gsap.to(this.wrapper, {
			scale: 0,
			ease: "power4",
			duration: 1,

			onComplete: () => {
				window.lenis.start();
				this.lightBox.classList.add("hide");
			},
		});
	}

	next() {
		this.isAnimated ? null : this.changePic(1);
	}

	prev() {
		this.isAnimated ? null : this.changePic(-1);
	}

	changePic(val) {
		this.isAnimated = true;
		const num = Number(this.currentPic.getAttribute("data-num"));
		let returnVal;

		if (num + val > this.thumbs.length - 1) {
			returnVal = 0;
		} else if (num + val < 0) {
			returnVal = this.thumbs.length - 1;
		} else {
			returnVal = num + val;
		}

		this.oldPic = this.currentPic;
		this.currentPic = this.thumbs[returnVal];

		let clone = this.wrapper.cloneNode(true);
		this.lightBox.appendChild(clone);

		gsap.set(this.wrapper, {
			x: () => {
				return val === -1 ? "-=120vw" : "+=120vw";
			},
		});

		this.img.setAttribute("src", this.currentPic.getAttribute("data-link"));

		this.currentEl.innerHTML = Number(this.currentPic.getAttribute("data-num")) + 1;

		gsap.to([clone, this.wrapper], {
			x: () => {
				return val === -1 ? "+=120vw" : "-=120vw";
			},
			ease: "power4",
			duration: 1.2,
			onComplete: () => {
				clone.remove();
				this.isAnimated = false;
			},
		});
	}
}
