import addRoundCorners from "./rounder.js";

export default class masker {
	constructor() {
		this.heroMask = null;
		this.footerMask = null;
		this.subPage = null;

		this.catchMasks();
	}

	catchMasks() {
		this.heroMask = document.querySelector("#hero-mask-path");
		this.footerMask = document.querySelector("#footer-mask-path");
		this.subPage = document.querySelector("#subpage-hero__mask-path");

		this.heroMask ? this.createHeroMask() : null;
		this.subPage ? this.createSubpageHeroMask() : null;
		this.footerMask ? this.createFooterMask() : null;
	}

	createSubpageHeroMask() {
		this.updateSubpageHeroMask();

		window.addEventListener("resize", (e) => {
			setTimeout(this.updateSubpageHeroMask(e), 100);
		});
	}

	createHeroMask() {
		this.updateHeroMask();

		window.addEventListener("resize", (e) => {
			setTimeout(this.updateHeroMask(e), 100);
		});
	}

	createFooterMask() {
		this.updateFooterMask();

		window.addEventListener("resize", (e) => {
			setTimeout(this.updateFooterMask(e), 100);
		});
	}

	updateHeroMask(e) {
		//console.log("hero update")

		let str = "M100 0 ";

		let area = {
			width: document.querySelector(".slider-wrapper").clientWidth,
			height: document.querySelector(".slider-wrapper").clientHeight,
		};

		let heroSliderRotatorArea = document.querySelector(".slider-buttons");
		let mainpageMenu = document.querySelector("#hero-menu");
		let scrollDown = document.querySelector(".scroll-down");

		let slides = Array.from(document.querySelectorAll(".slider-item"));

		if (slides.length == 1) {
			heroSliderRotatorArea.style.display = "none";
		}

		str += `L ${area.width * 0.98 - mainpageMenu.clientWidth} 0 L ${area.width * 0.98 - mainpageMenu.clientWidth} ${
			mainpageMenu.clientHeight
		} L ${area.width} ${mainpageMenu.clientHeight} L ${area.width} ${area.height * 0.9} L ${
			area.width - heroSliderRotatorArea.clientWidth
		} ${area.height * 0.9} L ${area.width - heroSliderRotatorArea.clientWidth} ${area.height} L ${
			scrollDown.clientWidth
		} ${area.height} L ${scrollDown.clientWidth} ${area.height * 0.9} L 0 ${area.height * 0.9} L 0 0 L 100 0 Z`;

		this.heroMask.setAttribute("d", str);
		this.heroMask.setAttribute("d", addRoundCorners(this.heroMask, 70));
	}

	updateFooterMask(e) {
		let str = "M";

		let wlitz = {
			width: document.querySelector(".made-by-wlitz").clientWidth,
			height: document.querySelector(".made-by-wlitz").clientHeight,
		};

		let area = {
			width: document.querySelector(".footer-wrapper").clientWidth,
			height: document.querySelector(".footer-wrapper").clientHeight,
		};

		if (innerWidth < 768) {
			str += `${area.width * 0.5} 0 L ${area.width} 0 L ${area.width} ${area.height} L 0 ${area.height} L 0 ${
				area.height * 0.05
			} L ${area.width * 0.3} ${area.height * 0.05} L ${area.width * 0.3} 0 L ${area.width * 0.5} 0 Z`;
		} else {
			str += `${area.width * 0.5} 0 L ${area.width} 0 L ${area.width} ${area.height - wlitz.height} L ${
				area.width - wlitz.width
			} ${area.height - wlitz.height} L ${area.width - wlitz.width} ${area.height} L 0 ${area.height} L 0 ${
				area.height * 0.1
			} L ${area.width * 0.1} ${area.height * 0.1} L ${area.width * 0.1} 0 L ${area.width * 0.5} 0 Z`;
		}

		this.footerMask.setAttribute("d", str);
		this.footerMask.setAttribute("d", addRoundCorners(this.footerMask, 70));
	}

	updateSubpageHeroMask(e) {
		let str = "M120 0 ";

		let area = {
			width: document.querySelector(".hero-subpage-wrapper").clientWidth,
			height: document.querySelector(".hero-subpage-wrapper").clientHeight,
		};

		str += `L ${area.width * 0.65} 0 L ${area.width * 0.65} ${area.height * 0.1} L ${area.width} ${
			area.height * 0.1
		} L ${area.width} ${area.height} L 0 ${area.height} L 0 0 L 120 0 Z`;

		this.subPage.setAttribute("d", str);
		this.subPage.setAttribute("d", addRoundCorners(this.subPage, 70));
	}
}
