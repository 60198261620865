
const svg = document.querySelector("svg");
const SVGNS = svg.namespaceURI;

let coordinates = [];
let lines = [];

function init(path) {
	coordinates = [];
	lines = [];
	// get the points in an array
	const rawCoordinates = path
		.getAttribute("d")
		.replace(/[mlz]/gi, "")
		.split(" ")
		.filter((c) => c.trim() != "");

	for (let i = 0; i < rawCoordinates.length; i += 2) {
		const coor = { x: rawCoordinates[i], y: rawCoordinates[i + 1] };
		coordinates.push(coor);
	}

	const numberOfCoordinates = coordinates.length;
	
	let largestRadius = 0;
	for (let i = 0; i < numberOfCoordinates; i++) {
		const coorBefore = i === 0 ? coordinates[numberOfCoordinates - 1] : coordinates[i - 1];
		const coor = coordinates[i];
		const coorAfter = i === numberOfCoordinates - 1 ? coordinates[0] : coordinates[i + 1];

		//  construct temporary line path (beforLine) going from point to point before current point
		const lineBefore = getLine(coor, coorBefore);

		//  construct temporary line path (afterLine) going from point to point after current point
		const lineAfter = getLine(coor, coorAfter);

		// Line between two lines
		let lineBetween = getLine(coorBefore, coorAfter);
		const lineBetweenLength = lineBetween.getTotalLength();
		const middlePoint = lineBetween.getPointAtLength(lineBetweenLength / 2);
		lineBetween = getLine(coor, middlePoint);

		const maxRadius = parseInt(Math.min(lineBefore.getTotalLength(), lineAfter.getTotalLength()) / 2);
		largestRadius = maxRadius > largestRadius ? maxRadius : largestRadius;

		lines.push({ lineBefore, lineAfter, coor, lineBetween, maxRadius });
	}
}

export default function addRoundCorners(path, rounded=56) {
	init(path);
	// find radius
	const radius = rounded;
	const radius2 = 0;

	// for each point
	const numberOfCoordinates = coordinates.length;
	let d = "";
	
	for (let i = 0; i < numberOfCoordinates; i++) {
	
		let { lineBefore, lineAfter, coor, lineBetween, maxRadius } = lines[i];
		const minorRadius = Math.min(radius, maxRadius);
		const minorRadius2 = Math.min(radius2, maxRadius);
		const beforePoint = lineBefore.getPointAtLength(minorRadius);

		const afterPoint = lineAfter.getPointAtLength(minorRadius);
		const beforePoint2 = lineBefore.getPointAtLength(minorRadius2);
		const afterPoint2 = lineAfter.getPointAtLength(minorRadius2);

		coor = lineBetween.getPointAtLength(minorRadius2);

		// generate data to new rounded path
		if (i === 0 || i == numberOfCoordinates - 1) {
			if (i === 0) d += `M${getCoordinates(coor)} `;
			if (i === numberOfCoordinates - 1) d += `L${getCoordinates(coor)} `;
		} else {
			d += `${i === 0 ? "M" : "L"}${getCoordinates(beforePoint)} C${getCoordinates(beforePoint2)},${getCoordinates(
				afterPoint2
			)},${getCoordinates(afterPoint)} `;
		}
	}
	return d + " Z";
}

function getCoordinates(point) {
	return `${Math.round(point.x)} ${Math.round(point.y)}`;
}
function getLine(coor1, coor2) {
	const line = document.createElementNS(SVGNS, "path");
	line.setAttribute("d", `M ${coor1.x} ${coor1.y} L ${coor2.x} ${coor2.y}`);
	return line;
}
