import { marquee } from "../../utils/marquee.js";

export default class section7 {
	constructor() {
		this.trigger = document.querySelector(".section7-js");
		this.marqueeTarget = this.trigger.querySelector(".mainpage-title-marquee-js");

		this.init();
	}

	init() {
		marquee(this.trigger, this.marqueeTarget);
	}
}
