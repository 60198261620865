import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export function marquee(trigger, target) {
	gsap.to(target, {
		xPercent: -80,
		scrollTrigger: {
			trigger: trigger,
			//markers: true,
			toggleActions: "restart none none reverse",
			scrub: true,
			start: "top bottom",
			end: "bottom bottom",
		},
	});
}
