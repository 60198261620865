import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

var convertTime = function (input) {
	var pad = function (input) {
		return input < 10 ? "0" + input : input;
	};
	return [pad(Math.floor((input % 3600) / 60)), pad(Math.floor(input % 60))].join(":");
};

export default class productVideo {
	constructor() {
		this.wrapper = document.querySelector(".custom-video-player-wrapper");
		this.fullscreenVideo = document.querySelector(".product-fullscreen-video");
		this.videoEl = this.fullscreenVideo.querySelector("video");

		this.timeWrapper = document.querySelector(".video-time-wrapper");
		this.timeLabel = document.querySelector(".time-label-js");
		this.timeTL = gsap.timeline();

		this.timer = null;
		this.cursor = true;
		this.isPlay = false;

		this.createdTL = false;

		this.tl = gsap.timeline({
			onComplete: () => {
				this.isPlay ? this.videoEl.play() : this.videoEl.pause();
			},
			onReverseComplete: () => {
				this.fullscreenVideo.classList.add("hide");
			},
		});

		this.tl.fromTo(
			this.fullscreenVideo,
			{
				opacity: 0,
				transformOrigin: "center center",
				scale: 0.9,
				borderRadius: "2rem",
			},
			{
				scale: 1,
				opacity: 1,
				borderRadius: 0,
				ease: "power4",
				duration: 0.3,
			}
		);

		this.timeTL.pause(0);
		this.tl.pause(0);

		this.init();
	}

	init() {
		this.wrapper.addEventListener("click", (e) => {
			this.videoController();
		});
	}

	videoController() {
		this.isPlay ? this.stop() : this.play();
		this.isPlay = !this.isPlay;
	}

	play() {
		window.lenis.stop();
		this.fullscreenVideo.classList.remove("hide");
		this.tl.play(0);

		!this.createdTL ? this.createTimeAni() : null;
		
		this.timeTL.play();

		this.videoEl.addEventListener("timeupdate", (e) => {
			this.timeLabel.innerHTML = convertTime(this.videoEl.currentTime) + " | " + convertTime(this.videoEl.duration);
		});

		document.addEventListener("mousemove", this.playButtonController);
	}

	createTimeAni() {

		this.createdTL = true;

		this.timeTL.fromTo(
			this.timeLabel,
			{
				x: 0,
			},
			{
				x: innerWidth,
				duration: this.videoEl.duration,
				ease: "linear",
				repeat: -1,
			}
		);
	}

	stop() {
		window.lenis.start();
		this.tl.reverse();
		//this.timeTL.pause();

		document.removeEventListener("mousemove", this.playButtonController);
	}

	playButtonController() {
		let playButton = document.getElementById("pause-button");
		clearTimeout(this.timer);

		if (this.cursor) {
			gsap.to(playButton, {
				opacity: 1,
			});
		}
		this.timer = setTimeout(function () {
			this.cursor = false;
			gsap.to(playButton, { opacity: 0 });
		}, 5000);

		this.cursor = true;
	}
}
