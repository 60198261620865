import LeaderLine from "leader-line-new";
import gsap from "gsap";

export default class organizationClass {
	constructor() {
		this.el = []
		gsap.delayedCall(.5, this.createLines);
	}

	createLines() {
	
		gsap.utils.toArray("[data-line]").forEach((item) => {
			let attr = item.getAttribute("data-line-to");
			let start = document.querySelector(`[data-line-val= '${attr}']`);

			if (attr != null) {
				new LeaderLine(start, item, {
					color: "#DFDDE1",
					size: 2,
					startPlug: "disc",
					endPlug: "arrow",
					path: "fluid",
					startSocket: "bottom",
					endSocket: "top",
				});
			}
		});

		gsap.utils.toArray(".leader-line").forEach((item) => {
			document.querySelector("main").appendChild(item);
		});

		document.querySelector("main").append(document.getElementById("leader-line-defs"));
	}
}
