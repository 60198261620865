import { gsap } from "gsap";

export default class modalClass {
	constructor() {
		this.modalWrapper = document.querySelector(".modal");
		this.modalInner = document.querySelector(".modal__inner");
		this.closeBtn = document.querySelector(".modal__close-btn");
		this.btn = document.querySelector(".modal-button-js");
		this.overlay = document.querySelector(".modal__overlay");
        this.closeEl = [this.btn, this.overlay, this.closeBtn];

		window.modal = {};
		window.modal.isOpen = false;
		window.modal.open = () => {
			if (window.modal.isOpen === false) {
				window.lenis.stop();
				window.modal.isOpen = !window.modal.isOpen;

				this.modalWrapper.classList.remove("d-none");

				gsap.fromTo(
					this.overlay,
					{
						opacity: 0,
					},
					{
						opacity: 0.8,
						duration: 0.3,
						ease: "power4",
					}
				);

				gsap.fromTo(
					this.modalInner,
					{
						scale: 0,
					},
					{
						scale: 1,
						delay: 0.2,
						ease: "power4",
						duration: 1,
					}
				);
			}
		};

		window.modal.close = () => {
			if (window.modal.isOpen == true) {
				gsap.fromTo(
					this.overlay,
					{
						opacity: 0.8,
					},
					{
						opacity: 0,
						delay: 0.6,
						duration: 0.3,
						ease: "power4",
					}
				);

				gsap.fromTo(
					this.modalInner,
					{
						scale: 1,
					},
					{
						scale: 0,
						ease: "power4",
						duration: 1,
						onComplete: () => {
							window.lenis.start();
                            window.modal.isOpen = !window.modal.isOpen;
                            this.modalWrapper.classList.add("d-none");
						},
					}
				);
			}
		};

		this.set();
	}

	set() {
		this.closeEl.forEach((item) => {
			item.addEventListener("click", (e) => {
				window.modal.close();
			});
		});

		gsap.set(this.overlay, { opacity: 0 });
		gsap.set(this.modalInner, { scale: 0 });
	}
}
