import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";
import { marquee } from "../../utils/marquee.js";

gsap.registerPlugin(ScrollTrigger);

let progress;

export default class section4 {
	constructor() {
		this.trigger = document.querySelector(".section4-js");
		this.product = document.querySelector(".product-trigger-js");
		this.marqueeTarget = this.trigger.querySelector(".mainpage-title-marquee-js");

		this.init();
	}

	init() {
		let mm = gsap.matchMedia();

		marquee(this.trigger, this.marqueeTarget);


		mm.add("(min-width: 1200px)",() => {
			this.productScrolling();
		});

	}

	productScrolling() {
		
		gsap.to(this.product, {
			x: ()=> innerWidth - this.product.offsetWidth,
			overwrite: true,

			scrollTrigger: {
				trigger: this.product,
				invalidateOnRefresh: true,
				//markers: true,
				pin: this.product,
				toggleActions: "restart none none reverse",
				scrub: true,
				start: "top top",
				end: () => "+=" + innerHeight * 5,
			},
		});
	}
}
