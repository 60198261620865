import JustValidate from "just-validate";

export function formController() {
	document.querySelectorAll("form") ? catchFormElements() : null;
}

function catchFormElements() {
	const forms = Array.from(document.querySelectorAll("form"));

	const validators = [];

	forms.forEach((form) => {
		validators.push(
			new JustValidate(form, {
				errorLabelStyle: {
					fontSize: "11px",
					color: "#d93a43",
					marginTop: "0px",
				},
				focusInvalidField: true,
				lockForm: true,
			})
		);
	});

	validators.forEach((validator) => {
		Array.from(validator.form).forEach((item) => {
			switch (item.getAttribute("id")) {
				case "name":
					validator.addField("#name", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
						{
							rule: "minLength",
							value: 6,
							errorMessage: "En az 6 karakter olmalıdır.",
						},
					]);
					break;
				case "person-title":
					validator.addField("#person-title", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "corporation":
					validator.addField("#corporation", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "email":
					validator.addField("#email", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
						{
							rule: "email",
							value: true,
							errorMessage: "E-posta adresini kontrol ediniz.",
						},
					]);
					break;
				case "phone":
					validator.addField("#phone", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "project-type":
					validator.addField("#project-type", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "description":
					validator.addField("#description", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "birthday":
					validator.addField("#birthday", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "address":
					validator.addField("#address", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "school":
					validator.addField("#school", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "school-place":
					validator.addField("#school-place", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "company":
					validator.addField("#company", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "driving-licence":
					validator.addField("#driving-licence", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "working-year":
					validator.addField("#working-year", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;
				case "depertmant":
					validator.addField("#depertmant", [
						{
							rule: "required",
							errorMessage: "Bu alan zorunludur.",
						},
					]);
					break;

                default:
                    validator.onSuccess(async (event) => {
                        
                        window.modal.open();

                        let formData = new FormData(event.target);

                        let response = await fetch(validator.form.getAttribute("id") + ".php", {
                            method: "POST",
                            body: formData,
                        });

                        if (response.ok) {
                        } else {
                            console.log(response.status);
                        }

                        event.target.reset();
                    });
			}
		});
	});
}
