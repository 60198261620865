import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class stickyTitles {
	constructor() {
		this.stickyTitles = null;
		this.catch();
	}

	catch() {
		this.stickyTitles = gsap.utils.toArray(".sticky-title");
		this.stickyTitles.length > 0 ? this.createStickyAni() : null;
	}

	createStickyAni() {
		let mm = gsap.matchMedia();

		mm.add("(min-width: 1200px)", () => {
			this.stickyTitles.forEach((title) => {
				ScrollTrigger.create({
					pin: true,
					trigger: title.parentNode,
					endTrigger: title.parentNode.parentNode,
					start: "top 20%",
					end: "bottom center",
					//markers: true,
				});
			});
		});
	}
}
