import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default function appearAnimations() {
	const appears = document.querySelectorAll("[data-appear]");
	const appearsWrapper = document.querySelectorAll("[data-appears]");

	if (appears.length > 0) {
		appears.forEach((appear) => {
			gsap.from(appear, {
				opacity: 0,
				y: "+=80",
				duration: .8,
				delay: .08,
				ease: "power4",

				scrollTrigger: {
					trigger: appear,
					start: "top 90%",
					//markers: true,
				},
			});
		});
	}

	if (appearsWrapper.length > 0) {
		appearsWrapper.forEach((appears) => {
			gsap.from(appears.children, {
				opacity: 0,
				y: "+=80",
				duration: 0.8,
				delay: 0.08,
				stagger: .08,
				ease: "power4",

				scrollTrigger: {
					trigger: appears,
					start: "top 90%",
					//markers: true,
				},
			});
		});
	}
}
