import { marquee } from "../../utils/marquee.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class section5 {
	constructor() {
		this.trigger = document.querySelector(".section5-js");
		this.marqueeTarget = this.trigger.querySelector(".mainpage-title-marquee-js");
		this.videoPlayerTrigger = this.trigger.querySelector(".video-player-trigger-js");
		this.videoPlayer = this.videoPlayerTrigger.querySelector(".video-player-js");

		this.init();
	}

	init() {
		marquee(this.trigger, this.marqueeTarget);
		this.videoFrameAnimation();
	}

	videoFrameAnimation() {
		gsap.fromTo(
			this.videoPlayer,
			{
				scale: 0.2,
			},
			{
				scale: 1,
				scrollTrigger: {
					trigger: this.videoPlayerTrigger,
					//invalidateOnRefresh: true,
					//markers: true,
					toggleActions: "restart none none reverse",
					scrub: true,
					start: "top bottom",
					end: "bottom bottom",
				},
			}
		);
	}
}
