import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";
import { getDetector } from "../../utils/browserDtector.js";


gsap.registerPlugin(ScrollTrigger);

export default class section3 {
	constructor() {
		this.trigger = document.querySelector(".section3-js");

		this.containerSVG = document.querySelector(".section3-svg-container-js");

		this.mainSVG = document.querySelector(".slogan-svg-js");

		this.dahi = document.querySelector(".section3-slogan-dahi-js");
		this.solution = document.querySelector(".section3-slogan-solution-js");
		this.idea = document.querySelector(".section3-slogan-idea-js");
		this.best = document.querySelector(".section3-slogan-best-js");

		this.init();
	}

	init() {
		gsap.set(this.containerSVG, {			
			scale: () => innerWidth / 1820,
		});

		window.addEventListener("resize", () => {
			gsap.set(this.containerSVG, {
				scale: () => innerWidth / 1820,
			});
		});
		
		
		if (getDetector() != "Safari") {
			this.createAnimation();
		}
	}

	createAnimation() {
		let mm = gsap.matchMedia();
		let mm_ = gsap.matchMedia();

		mm.add("(min-width: 1200px)", () => {
			gsap.fromTo(
				[this.dahi, this.idea, this.best, this.solution],
				{
					x: (index, item) => {
						if (index % 2 === 0) {
							return -window.innerWidth;
						} else {
							return window.innerWidth;
						}
					},
				},
				{
					x: 0,
					stagger: 0.05,
					scrollTrigger: {
						trigger: this.trigger,
						invalidateOnRefresh: true,
						//markers: true,
						toggleActions: "restart none none reverse",
						scrub: true,
						start: "top bottom",
						end: () => "+=" + innerHeight * 2,
					},
				}
			);

			ScrollTrigger.create({
				//markers: true,
				trigger: this.trigger,
				pin: this.trigger,
				start: "top top",
				end: () => "+=" + innerHeight * 1.5,
			});
		})

		mm_.add("(max-width: 1199px)", () => {
			gsap.fromTo(
				[this.dahi, this.idea, this.best, this.solution],
				{
					x: (index, item) => {
						if (index % 2 === 0) {
							return -window.innerWidth;
						} else {
							return window.innerWidth;
						}
					},
				},
				{
					x: 0,
					stagger: 0.05,
					scrollTrigger: {
						trigger: this.trigger,
						//invalidateOnRefresh: true,
						//markers: true,
						toggleActions: "restart none none reverse",
						scrub: true,
						start: "top bottom",
						end: "center center",
					},
				}
			);
		});
	}
}
