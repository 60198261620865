import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger.js";
import {splitWord} from "../../utils/splitText.js";

gsap.registerPlugin(ScrollTrigger);

export default class section2 {
	constructor() {
		this.trigger = document.querySelector(".section2-js");
		this.text = document.querySelector(".section2-text-js");
		this.text_ = document.querySelector(".section2-text-small-js");
		this.text.innerHTML = splitWord(this.text);
		this.text_.innerHTML = splitWord(this.text_);

		this.createAnimation();
	}

	createAnimation() {
		let mm = gsap.matchMedia();
		let mm_ = gsap.matchMedia();

		mm.add("(min-width: 1200px)", () => {
			gsap.fromTo(
				this.text.children,
				{ opacity: 0.2 },
				{
					opacity: 1,
					stagger: 0.5,
					scrollTrigger: {
						trigger: this.trigger,
						invalidateOnRefresh: true,
						//markers: true,
						pin: this.trigger,
						toggleActions: "restart none none reverse",
						scrub: true,
						start: "top top",
						end: () => "+=" + innerHeight * 1.1,
					},
				}
			);
		});

		mm_.add("(max-width: 1199px)", () => {
			gsap.fromTo(
				this.text_.children,
				{ opacity: 0.2 },
				{
					opacity: 1,
					stagger: 0.5,
					scrollTrigger: {
						trigger: this.trigger,
						//invalidateOnRefresh: true,
						//markers: true,
						//pin: this.trigger,
						toggleActions: "restart none none reverse",
						scrub: true,
						start: "top center",
						end: "75% center",
					},
				}
			);
		});
	}
}