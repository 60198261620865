import productVideo from "../../components/product-video.js";
import "wave-audio-path-player";

export default class product {
	constructor() {
		this.videoItem = new productVideo();
		this.audioController();
	}

	audioController() {
		let playerSVG = document.querySelector("wave-audio-path-player");
		let shadow = playerSVG.shadowRoot;
		let mainwrapper = shadow.querySelector(".player");
		mainwrapper.style.width = "100%";
		mainwrapper.querySelector("#slider").style.flexGrow = "1";
		mainwrapper.querySelector("#slider").querySelector("#seek-slider").style.height="100%";
		let SVG = mainwrapper.querySelector("#slider").querySelector("svg");
		SVG.removeAttribute("width");
		SVG.removeAttribute("height");
		SVG.setAttribute("preserveAspectRatio", "xMidYMid meet");

		SVG.querySelector("#path1").setAttribute("vector-effect", "non-scaling-stroke");
		SVG.querySelector("#path2").setAttribute("vector-effect", "non-scaling-stroke");
	}
}
