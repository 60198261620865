import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";
import { Draggable } from "gsap/dist/Draggable.js";


gsap.registerPlugin(ScrollTrigger, Draggable);

export default class hero {
	constructor() {
		this.heroSection = "slider-container";
		this.slides = gsap.utils.toArray(".slider-item");
		this.currentSlide = 0;
		this.interval = 8000;
		this.timer = null;
		this.isAnimating = false;
		this.rotators = null;

		this.heroSliderRotatorArea = document.querySelector(".slider-buttons");
		this.sliderRotator = document.querySelector(".slider-rotator-item");

		this.createSlides();
	}

	createSlides() {
		let mmLaptop = gsap.matchMedia();
		let mmMobile = gsap.matchMedia();

		for (let i = 0; i < this.slides.length; i++) {
			let clone = this.sliderRotator.cloneNode(true);
			this.sliderRotator.remove();

			this.heroSliderRotatorArea.appendChild(clone);
			clone.setAttribute("data-slide", i);

			mmLaptop.add("(min-width: 1200px)", () => {
				clone.addEventListener("click", (e) => {
					if (
						e.target.parentNode === clone &&
						!this.isAnimating &&
						clone.getAttribute("data-slide") != this.currentSlide
					) {
						this.animateSlide(this.currentSlide, parseInt(e.target.parentNode.getAttribute("data-slide")));
						this.timeReset();
					}
				});
			});

			Draggable.create(gsap.utils.toArray(".slider-dragger-js"), {
				type: "x",

				onDragStart: (e) => {
					this.timeReset();
					e.target.setAttribute("drag-start", e.target.style.left);
				},
				onDragEnd: (e) => {
					if (parseInt(e.target.getAttribute("drag-start")) < e.target.style.left) {
						this.animateSlide(this.currentSlide, this.calculateSlideItem(-2));
					}
					else {
						this.animateSlide(this.currentSlide, this.calculateSlideItem());
					}					

					e.target.style.zIndex = "1";
					gsap.set(e.target, { x: 0 });
				},
				onClick: (e) => {
					e.target.style.zIndex = "1";
					gsap.set(e.target, { x: 0 });
				},

				onPress: (e) => {
					e.target.style.zIndex = "1";
					gsap.set(e.target, { x: 0 });
				}
			});
		}

		this.rotators = gsap.utils.toArray(".slider-button");
		document.querySelector(".slider-button").classList.add("active");
		this.slides.length > 1 ? this.timeStart() : null;
	}

	animateSlide(currentSlide, newSlide) {
		this.isAnimating = !this.isAnimating;

		this.slides[newSlide].classList.add("active");
		this.slides[currentSlide].classList.add("actived");

		let img = this.slides[newSlide].querySelector(".img-wrapper");
		let leftSide = gsap.utils.toArray("[class*='slider-text-wrapper']>*", this.slides[newSlide]);

		gsap.fromTo(
			img,
			{
				opacity: 0,
				scale: 1.2,
			},
			{
				opacity: 1,
				scale: 1,
				duration: 1.5,
				ease: "power4.inOut",
			}
		);
		gsap.fromTo(
			leftSide,
			{
				x: "-=500",
				opacity: 0,
			},
			{
				x: 0,
				opacity: 1,
				duration: 1.5,
				ease: "power4.inOut",
				stagger: 0.2,
				onComplete: () => {
					this.currentSlide = newSlide;
					this.isAnimating = !this.isAnimating;
					this.timeStart();
					this.slides[currentSlide].classList.remove("actived");
					this.slides[newSlide].classList.add("actived");
					this.slides[newSlide].classList.remove("active");
				},
			}
		);

		this.changeRotator(newSlide);
	}

	timeStart() {
		this.timer = setInterval(() => this.animateSlide(this.currentSlide, this.calculateSlideItem()), this.interval);
	}

	timeReset() {
		clearInterval(this.timer);
	}

	calculateSlideItem(val=1) {
		this.timeReset();
		let num;
		this.currentSlide + val > this.slides.length - 1 || this.currentSlide + val < 0 ? (num = 0) : (num = this.currentSlide + 1);

		/* console.log(num); */
		return num;
	}

	changeRotator(newSlide) {
		this.rotators.forEach((item) => {
			item.classList.remove("active");
		});

		this.rotators[newSlide].classList.add("active");
	}
}
